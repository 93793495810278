import React from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'

import Home from './Components/Home/Home'
import Services from './Components/Services/Services'
import About from './Components/About/About'
import AlcoholCalculator from './Components/AlcoholCalculator/AlcoholCalculator'
import Packages from './Components/Packages/Packages'
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy'

export default (
    <BrowserRouter>
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/services' element={<Services />}/>
            <Route path='/about' element={<About />}/>
            <Route path='/alcoholCalculator' element={<AlcoholCalculator />}/>
            <Route path='/packages' element={<Packages />}/>
            <Route path='/privacy-policy' element={<PrivacyPolicy />}/>
        </Routes>
    </BrowserRouter>
)