import React, { useState, useRef, useEffect } from 'react'
import './Nav.css'
import tavernLogo from '../../Images/tavernLogo.png';
import menuButton from '../../Images/menuButton.jpg';

const CloseMenuOnOutsideClick = ({ref, menuOpen, toggleMenu}) => {
  useEffect(() => {
    const handleOutisdeClick = e => {
      if (menuOpen &&
          ref.current &&
          ref.current !== e.target &&
          e.target.className !== 'hamburger') {
        toggleMenu(false)
      }
    }
    document.addEventListener('click', handleOutisdeClick)
    return () => document.removeEventListener('click', handleOutisdeClick)
  })
}

const Nav = () => {
  const [menuOpen, toggleMenu] = useState(false)
  const mobileMenu = useRef(null);
  CloseMenuOnOutsideClick({
    ref:mobileMenu,
    menuOpen,
    toggleMenu
  })

  return (
    <div className="nav">
      <div className="logo">
        <a className="company-name" href="/">
          <img src={tavernLogo} className="logo" alt="logo" />
        </a>
      </div>

      <div className="nav-menu">
        <a className="nav-menu-link" href="/">HOME</a>
        <a className="nav-menu-link" href="/services">SERVICES</a>
        <a className="nav-menu-link" href="/about">ABOUT US</a>
        {/* <a className="nav-menu-link" href="/alcoholCalculator">Alcohol Calculator</a> */} */}
        <a className="nav-menu-link" href="/packages">PACKAGES</a>
        <a className="nav-menu-link" href="/FAQ">FAQ</a>
      </div>

      <button className="menu-button" onClick={()=>toggleMenu(!menuOpen)}><img className="hamburger" src={menuButton} alt="menu-button" /></button>
      {menuOpen ? (
        <div className="mobile-menu" ref={mobileMenu}>
          <a className="nav-menu-link" href="/">HOME</a>
          <a className="nav-menu-link" href="/services">SERVICES</a>
          {/* <a className="nav-menu-link" href="/about">About</a>
          <a className="nav-menu-link" href="/alcoholCalculator">Alcohol Calculator</a> */}
          <a className="nav-menu-link" href="/packages">PACKAGES</a>
        </div>
      ):null}
    </div>
  )
}

export default Nav