import React from 'react'
import './Services.css'
import bartender2 from '../../Images/main-page.jpg';

const Services = () => {
    return (
      <div>
        <img
          src={bartender2}   className="pricing-header-img"
          alt="bartender2"
        />
      </div>
    )
}

export default Services
