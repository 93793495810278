import React from 'react'
import './Footer.css'
import ig from '../../Images/ig.png'
import linkedin from '../../Images/linkedin.png'
import fb from '../../Images/fb.png'
// import email from '../../Images/email.png'

const Footer = () => {
    return (
        <div className="Footer">
            <div className="pre-footer">
                <a 
                    href="https://www.instagram.com/tavernbarservices/"
                    className='social-icon'
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={ig} className='social-icon' alt='ig'/>
                </a>
                <a 
                    href="https://www.linkedin.com/company/tavern-bar-services/"
                    className='social-icon'
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={linkedin} className='social-icon' alt='linkedin'/>
                </a>
                <a 
                    href="https://www.facebook.com/andrew.beutler.7"
                    className='social-icon'
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={fb} className='social-icon' alt='fb'/>
                </a>
                {/* <a 
                    // href="https://www.facebook.com/andrew.beutler.7"
                    className='social-icon'
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={email} className='social-icon' alt='fb'/>
                </a> */}
                {/* <a target="_blank" href="https://icons8.com/icon/84884/instagram">Instagram</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a> */}
            </div>
            <div className="main-footer">
                <a className="privacy-link" href='/privacy-policy'>Privacy Policy</a>
                <div className="footer-line-1">
                    <p className="line-1-p">TAVERN BAR SERVICES</p>
                    <p className="line-1-p">(385) 207-6514</p>
                    <p className="line-1-p">HELLO@TAVERNBARSERVICES.COM</p>
                </div>
                {/* <p className="footer-line-2">FUN SEXY PROFESSIONAL</p> */}
            </div>
        </div>
    )
}

export default Footer
