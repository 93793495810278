import React from 'react'
import './Home.css'
import bartender from '../../Images/main-page.jpg';
import cocktailBanner from '../../Images/stock-drinks.jpg';

const Home = () => {
    return (
        <div>
            <img src={bartender} className="header-img" alt="bartender"/>
            <div className="header-content">
                <n className="main-text">PROFESSIONAL BARTENDING SERVICES</n>
                <p className="header-text">Events - Weddings - Parties</p>
                <a href="/pricing"><button className='button-1'>GET A QUOTE</button></a>
            </div>
            <div className="home-body">
                <h1 className="home-body-text">Crafting Cheers, Creating Memories</h1>
                <h3 className="home-body-text">Welcome to Tavern Bar Services, your go-to destination for exceptional bar services. We specialize in catering to your unique occasion, ensuring a stress-free experience for you and a memorable time for your guests. With our skilled bartenders and attention to detail, we bring the perfect blend of professionalism and fun to every gathering. From intimate weddings to large corporate functions, our dedicated team will tailor our services to suit your specific needs, leaving you free to sit back and enjoy your event.
</h3>
            </div>
            <a href="/pricing" className="banner-button-link">
                <img src={cocktailBanner} className="banner-button-img" alt="cocktail-banner"/>
            </a>
        </div>
    )
}

export default Home
