import React, { useState, useRef } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Packages.css'
import bartender2 from '../../Images/main-page.jpg';
import emailjs from '@emailjs/browser'

const Packages = () => {
    const form = useRef();

    const [descOneOpen, setDescOneOpen] = useState(false)
    const [descTwoOpen, setDescTwoOpen] = useState(false)
    const [descThreeOpen, setDescThreeOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const submit = e => {
      e.preventDefault()
      setLoading(true)
      // emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_PUBLIC_KEY')
      emailjs.sendForm('service_yt6q5b9', 'template_l5co87m', form.current, '9jn9S_al4iCuOsQ7d')
        .then((result) => {
          setLoading(false)
          toast("Success! Info submitted.  We'll get back to you ASAP, excited to party with you!")
          console.log(result.text);
        }, (error) => {
          setLoading(false)
          toast("Network Error: Info not submitted.  Book a call with us and we can take care of it together!")
          console.log(error.text);
        });
    }
    
    return (
      <div>
        <ToastContainer />
        <img src={bartender2} className="pricing-header-img" alt="bartender2"/>
        <div className="pricing-body">
          <h1>Packages</h1>
          
          <div className="packageDescriptions">
            <button
              onClick={()=>setDescOneOpen(!descOneOpen)}
              className="descriptionButton"  
            >
              WINE & BEER
              <div>&darr;</div>
            </button>
            {descOneOpen ? (
              <div className="packageDescription">
                <p>This package includes:</p>
                <p>- Any Wine & Beer of your choosing, we also provide a service of quality recommendations</p>
                <p>- 4 hours of an open bar with additional time always available</p>
                <p>- ice</p>
                <p>- Coke or Pepsi products</p>
                <p>- Disposable cups, napkins & straws</p>
                <p>- Liquor liability insurance</p>
                <p>- This package does not include alcohol, which can either be provided by you or added to your invoice. Any and all remaining alcohol will be given back to you.</p>
              </div>
            ):null}
            
            <button
              onClick={()=>setDescTwoOpen(!descTwoOpen)}
              className="descriptionButton"  
            >
              BASIC COCKTAIL PACKAGE
              <div>&darr;</div>
            </button>
            {descTwoOpen ? (
              <div className="packageDescription">
                <p>This package includes:</p>
                <p>- Simple cocktails, with any number of basic mixers</p>
                <p>- We provide an option of a service of quality alcohol recommendations</p>
                <p>- 4 hours of an open bar with additional time always available</p>
                <p>- ice</p>
                <p>- Coke or Pepsi products</p>
                <p>- Disposable cups, napkins & straws</p>
                <p>- Liquor liability insurance</p>
                <p>- This package does not include alcohol, which can either be provided by you or added to your invoice. Any and all remaining alcohol will be given back to you.</p>
              </div>
            ):null}
            
            <button
              onClick={()=>setDescThreeOpen(!descThreeOpen)}
              className="descriptionButton"  
            >
              SIGNATURE COCKTAIL PACKAGE
              <div>&darr;</div>
            </button>
            {descThreeOpen ? (
              <div className="packageDescription">
                <p>This package includes:</p>
                <p>- 1 signature drink. With 6 different options to choose from: Old fashioned, Mai Tai’s, Mojito’s, Cosmopolitan, Margaritas and Moscow Mules. This package also includes simple cocktails.</p>
                <p>- We provide an option of a service of quality alcohol recommendations</p>
                <p>- 4 hours of an open bar with additional time always available</p>
                <p>- ice</p>
                <p>- Coke or Pepsi products</p>
                <p>- Disposable cups, napkins & straws</p>
                <p>- Liquor liability insurance</p>
                <p>- This package does not include alcohol, which can either be provided by you or added to your invoice. Any and all remaining alcohol will be given back to you.</p>
              </div>
            ):null}
          </div>

          <h1>Discounts</h1>
          <p>We offer discounts, to the following: Referrals, Military, Non-Profit organizations, First Responders and Educators.</p>
          
          <h1>Get A Quote</h1>
   

          <form className="quote-form column" ref={form} onSubmit={submit}>
            <label className="quote-label">Name
              <input className="form-input" type="text" name="name" />
            </label>
            <label className="quote-label">Email
              <input className="form-input" type="text" name="email" />
            </label>
            <label className="quote-label">Phone Number
              <input className="form-input" type="text" name="phone" />
            </label>
            {/* this event date looks tiny on my phone */}
            <label className="quote-label">Event Date
              <input className="form-input" type="date" name="date" />
            </label>
            <label className="quote-label">Event Location
              <input className="form-input" type="text" name="location" />
            </label>

            <label className="quote-label">Event Type
              <select name="event-type">
                <option value="Corporate Event">Corporate Event</option>
                <option value="Wedding">Wedding</option>
                <option value="Rehearsal Dinner">Rehearsal Dinner</option>
                <option value="Birthday">Birthday</option>
                <option value="Family">Family</option>
                <option value="Fundraiser">Fundraiser</option>
                <option value="Party">Party</option>
                <option value="Holiday Party">Holiday Party</option>
                <option value="Graduation">Graduation</option>
                <option value="Gala">Gala</option>
                <option value="Other">Other</option>
              </select>
            </label>

            <label className="quote-label">Expected Guest Count
              <input className="form-input" type="text" name="count" />
            </label>
            
            <div className="column quote-label margin-bottom">Who would you like to provide the alcohol?
              <label className="provider"><input name="provider" type="radio" value="Tavern" />Tavern to provide</label>
              <label className="provider"><input name="provider" type="radio" value="Customer" />I'll provide my own</label>
              <label className="provider"><input name="provider" type="radio" value="Either" />Open to both options</label>
              <label className="provider"><input name="provider" type="radio" value="Non-alcoholic" />Neither, non-alcoholic event</label>
            </div>
            

            <label className="quote-label">Any other details you'd like us to know?
              <textarea className="misc" type="text" name="miscellaneous" />
            </label>
            
            <label className="quote-label">Where did you hear about us?
              <select name="discovery">
                <option value="google">Google</option>
                <option value="fb">Facebook</option>
                <option value="ig">Instagram</option>
                <option value="linkedin">LinkedIn</option>
                <option value="friend">Friend Recommendation</option>
                <option value="repeatCustomer">Hired Us Before</option>
                <option value="venueReferral">Venue Referral</option>
                <option value="clientReferral">Client Referral</option>
                <option value="other">Other</option>
              </select>
            </label>

            <button className="button-1 quote-button" type="submit">
              {loading ? <div className="loading-spinner"></div> : <div>SUBMIT</div>}
            </button>
          </form>
        </div>
      </div>
    )
}

export default Packages
