import React from 'react'
import './App.css';
import Nav from './Components/Nav/Nav'
import Routes from './Routes'
import Footer from './Components/Footer/Footer'
import { PopupWidget } from "react-calendly"

function App() {

  return (
    <div className="App">
        <Nav />
        <PopupWidget
          url="https://calendly.com/tavern-bar-service/30min"
          /*
          * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
          * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
          */
          rootElement={document.getElementById("root")}
          text="Book a call with me"
          textColor="black"
          color="rgb(236, 193, 202)"
        />
        <div className="Routes">{Routes}</div>
        <Footer />
    </div>
  );
}

export default App;
