import React from 'react'
import './About.css'

const About = () => {
    console.log('working')
    return (
        <div>
            About.js
        </div>
    )
}

export default About
