import React from 'react'
import './AlcoholCalculator.css'

const AlcoholCalculator = () => {
    return (
        <div>
            AlcoholCalculator.js
        </div>
    )
}

export default AlcoholCalculator
