import React from 'react'
import './PrivacyPolicy.css'

const PrivacyPolicy = () => {
    
    return (
        <div>Privacy Policy.js</div>
    )
}

export default PrivacyPolicy
